import { useState } from "react";
import { languages } from "../../constant/Languages";
import "./inputStyle.css";

type NewTemplateObj = {
  language: string;
  isRendering: boolean;
  text: string;
  error: any;
};

const EmailInputComponent = ({
  label,
  placeholder,
  name,
  err,
  touched,
  templateData,
  setTemplateData,
}: {
  label: string;
  placeholder: string;
  name: string;
  err: any;
  touched: any;
  templateData: NewTemplateObj[];
  setTemplateData: (data: any) => void;
}) => {
  const [showInputField, setShowInputField] = useState<boolean>(false);
  const [templateState, setTemplateState] = useState<NewTemplateObj>({
    language: "",
    text: "",
    error: "",
    isRendering: false,
  });

  const handleLanguageChange = (index: any, language: any) => {
    const temp = templateData.map((item, ind) =>
      ind === index ? { ...item, language: item.language } : item
    );
    setTemplateData(temp);
  };

  function validateHTML(html: string) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const isError = doc.querySelector("parsererror");
    if (isError) {
      return false;
    }

    return doc.documentElement.outerHTML;
  }

  const toggleView = (index: number) => {
    const validateResult = validateHTML(templateData[index].text);
    const updatedTemplateData = templateData.map((item, ind) => {
      if (ind === index) {
        if (validateResult) {
          return {
            ...item,
            text: validateResult,
            error: "",
            isRendering: !item.isRendering,
          };
        } else {
          return {
            ...item,
            error: "Invalid HTML content. Please correct it and try again.",
          };
        }
      }
      return item;
    });

    setTemplateData(updatedTemplateData);
  };

  const toggleNewInput = () => {
    const validateResult = validateHTML(templateState.text);
    if (validateResult) {
      setTemplateState((prevState) => ({
        ...prevState,
        text: validateResult,
        isRendering: !prevState.isRendering,
        error: "",
      }));
    } else {
      setTemplateState((prevState) => ({
        ...prevState,
        error: "Invalid HTML content. Please correct it and try again.",
      }));
    }
  };

  const removeHandler = (index: number) => {
    if (templateData.length <= 1) {
      setTemplateData((prevData: any[]) =>
        prevData.map((item: any, ind: number) =>
          ind === index
            ? { ...item, error: "At least one template is required" }
            : item
        )
      );
      return;
    }

    const updatedData = templateData.filter((_, ind) => ind !== index);
    setTemplateData(updatedData);
  };

  const handleSave = () => {
    if (!templateState.language || !templateState.text) {
      setTemplateState((prev) => ({
        ...prev,
        error: "Language and text are required.",
      }));
      return;
    }

    const languageExists = templateData.some(
      (item) => item.language === templateState.language
    );

    if (languageExists) {
      setTemplateState((prev) => ({
        ...prev,
        error:
          "This language has already been used. Please select a different language.",
      }));
      return;
    }

    let validatedTemplate: string | boolean = templateState.text;
    if (!validatedTemplate) {
      validatedTemplate = validateHTML(templateState.text);
      if (!validatedTemplate) {
        setTemplateState((prev) => ({
          ...prev,
          error: "Invalid HTML content. Please correct it and try again.",
        }));
        return;
      }
    }

    const newTemplateObj = {
      ...templateState,
      text: validatedTemplate,
      isRendering: false,
      error: "",
    };

    setTemplateData((prevData: any) => [...prevData, newTemplateObj]);

    setTemplateState({
      language: "",
      isRendering: false,
      text: "",
      error: "",
    });

    setShowInputField(false);
  };

  const oldTemplateChange = (text: string, ind: number) => {
    const copy = templateData.map((item, index) => {
      if (ind === index) {
        return { ...item, text: text };
      }
      return item;
    });
    setTemplateData(copy);
  };

  return (
    <div className="">
      <label className="block mb-2 mt-3 text-xl font-bold text-darkgray font-roboto items-start flex">
        {label}
      </label>
      <div className="bg-gray-50 border font-roboto font-normal text-black border-slate-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none">
        <div>
          {templateData.map((item: NewTemplateObj, ind: number) => (
            <div key={item.language + ind} className="mb-4">
              {item.isRendering ? (
                <div
                  className="w-full h-200px overflow-y-auto bg-gray-50 border border-slate-300 rounded-lg p-2.5"
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                />
              ) : (
                <textarea
                  className="w-full bg-gray-50 border font-normal font-roboto text-black border-slate-300 text-gray-900 text-sm rounded-lg block p-2.5 outline-none"
                  style={{ height: 200 }}
                  name="bookingEmailTemplate"
                  value={item.text}
                  onChange={(e) => oldTemplateChange(e.target.value, ind)}
                  placeholder="Enter HTML content here..."
                />
              )}

              {(touched?.bookingEmailTemplate && err?.bookingEmailTemplate) ||
              item.error ? (
                <p className="text-red-500 mt-2">
                  {err?.bookingEmailTemplate || item.error}
                </p>
              ) : null}
              <div className="flex gap-2 mt-2">
                <button
                  className=" border rounded bg-aqua text-white px-2 py-2"
                  type="button"
                  onClick={() => toggleView(ind)}
                >
                  {item.isRendering ? "View HTML" : "Render"}
                </button>
                <select
                  value={item.language}
                  onChange={(e) => {
                    handleLanguageChange(ind, e.target.value);
                  }}
                  className="bg-gray-50 border border-slate-300 text-gray-900 text-sm rounded-lg p-1 outline-none"
                >
                  <option value="">Select Language</option>
                  {languages?.map((elm) => (
                    <option key={elm.code} value={elm.code}>
                      {elm.name}
                    </option>
                  ))}
                </select>
                <button
                  onClick={() => removeHandler(ind)}
                  type={"button"}
                  className="ml-2 px-2 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none"
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
        </div>

        {showInputField && (
          <div className=" mb-2">
            {templateState.isRendering ? (
              <div
                className="w-full h-200px overflow-y-auto bg-gray-50 border border-slate-300 rounded-lg p-2.5"
                dangerouslySetInnerHTML={{
                  __html: templateState.text,
                }}
              />
            ) : (
              <textarea
                className="w-full bg-gray-50 border font-normal font-roboto text-black border-slate-300 text-gray-900 text-sm rounded-lg block p-2.5 outline-none"
                style={{ height: 200 }}
                name="bookingEmailTemplate"
                value={templateState.text}
                onChange={(e) =>
                  setTemplateState((prevState) => ({
                    ...prevState,
                    text: e.target.value,
                  }))
                }
                placeholder="Enter HTML content ..."
              />
            )}

            {(touched?.bookingEmailTemplate && err?.bookingEmailTemplate) ||
            templateState.error ? (
              <p className="text-red-500 mt-2">
                {err?.bookingEmailTemplate || templateState.error}
              </p>
            ) : null}
            <div className="flex gap-2 mt-2">
              <button
                className=" border rounded bg-aqua text-white px-2 py-2"
                type="button"
                onClick={toggleNewInput}
              >
                {templateState.isRendering ? "View HTML" : "Render"}
              </button>
              <select
                value={templateState.language}
                onChange={(e) =>
                  setTemplateState((prevState) => ({
                    ...prevState,
                    language: e.target.value,
                  }))
                }
                className="bg-gray-50 border border-slate-300 text-gray-900 text-sm rounded-lg p-1 outline-none"
              >
                <option value="">Select Language</option>
                {languages?.map((elm) => (
                  <option key={elm.code} value={elm.code}>
                    {elm.name}
                  </option>
                ))}
              </select>
              <button
                onClick={() => {
                  setShowInputField(false);
                  setTemplateState({
                    text: "",
                    error: "",
                    isRendering: false,
                    language: "",
                  });
                }}
                type="button"
                className="ml-2 px-2 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none"
              >
                Remove
              </button>

              <button
                onClick={handleSave}
                type={"button"}
                className="ml-2 px-2 py-1 bg-aqua text-white rounded hover:bg-blue-600 focus:outline-none"
              >
                Save
              </button>
            </div>
          </div>
        )}

        <label htmlFor={name} className="font-roboto font-normal">
          <span className="px-2 py-1 bg-lightaqua border-2 mt-2  m-1 font-roboto font-normal cursor-pointer">
            <button type="button" onClick={() => setShowInputField(true)}>
              + Add More Template
            </button>
          </span>
        </label>
      </div>
    </div>
  );
};
export default EmailInputComponent;
