import React, { useEffect, useState } from "react";
import Images from "../../../constant/Images";
import { STORAGE_PATH, articleEditInput } from "../../../constant/string";
import InputComponent from "../../inputComponent/InputComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import services from "../../../services/services";
import { useAppDispatch } from "../../../store/app/store";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { articlesListThank } from "../../../store/features/articlesListSlice";
import SelectComponent from "../../selectComponent/SelectComponent";
import { log } from "console";
import EditInputComponent from "../../inputComponent/EditInputComponent";
const EditArticle = ({ data }: any) => {
  // const { Tours, isLoading, isError, isSuccess } = useSelector(
  //   (state: any) => state.tours
  // );
  const { Topics, isSuccess, isLoading, isError } = useSelector(
    (state: any) => state.topicList
  );
  const dispatch: any = useAppDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const [File, setFile] = useState<any>({});
  const handleAddTour = async (payload: any) => {
    const res: any = await services.editArticle(data?.id, payload);
    if (res?.status == 200) {
      toast.success(res?.data.message);
      dispatch(articlesListThank());
    } else {
      toast.dismiss();
      toast.error("Something Went Wrong");
    }
  };
  const formik: any = useFormik({
    initialValues: {
      topicId: data?.topicId,
      name: data?.name,
      imageUrl: data?.imageUrl ,
      profileUrl: data?.profileUrl,
      title: data?.title,
      description: data?.description,
    },
    validationSchema: Yup.object({
      topicId:Yup.string().trim().required("This field is required"),
      name: Yup.string().trim().required("This field is required"),
      imageUrl: Yup.string().trim().required("This Field is  Required"),
      profileUrl: Yup.string().trim().required("This Field is  Required"),
      title: Yup.string().trim().required("This field is required"),
      description: Yup.string().trim().required("This field is required"),
    }),
    onSubmit: (values) => {
      handleAddTour({
        ...values,
        profileUrl: File?.profileUrl?.replace(`${STORAGE_PATH}/imageUpload/`, ""),
        imageUrl: File?.imageUrl?.replace(`${STORAGE_PATH}/imageUpload/`, ""),
      });
    },
    enableReinitialize: true,
  });

  const handleChangeOnFile = async (e: any) => {
    if (
      e?.target?.files[0]?.type === "image/jpg" ||
      e?.target?.files[0]?.type === "image/png" ||
      e?.target?.files[0]?.type === "image/jpeg" ||
      e?.target?.files[0]?.type === "image/svg"
    ) {
      
      formik.values[e.target.name] = e.target.value;
      const res = await services.uploadImage(e.target.files[0]);

      if (res?.data?.data?.url[0]?.path) {
        setFile({
          ...File,
          [e.target.name]: res?.data?.data?.url[0]?.path,
        });
        formik.errors[e.target.name] = "";
      } else {
        formik.values[e.target.name] = "";
        formik.errors[e.target.name] = "Please select correct img";
      }
    }
  };
  useEffect(() => {
    formik?.handleReset(formik.values);
    if (showModal) {
      (formik as any).values = {
        topicId: data?.topicId,
        name: data?.name,
        imageUrl: data?.imageUrl,
        profileUrl: data?.profileUrl,
        title: data?.title,
        description: data?.description,
      };
    }
  }, [showModal]);
  return (
    <>
      <img
        src={Images.edit}
        alt="edit"
        onClick={() => setShowModal(true)}
        className="ml-2 cursor-pointer w-6 h-6"
      />
      {showModal ? (
        <>
          <div className="justify-center items-center flex  fixed inset-0 z-50 outline-none">
            <div className="relative w-[900px] h-[600px] bg-white my-6 rounded overflow-y-auto">
              {/*content*/}

              {/*header*/}

              <div className="flex justify-between text-xl font-semibold bg-lightaqua w-[100%] h-[70px] items-center rounded">
                <div></div>
                <h3 className="font-sans font-bold">Add Article</h3>
                <div className="bg-aquadark w-[70px] h-[70px] rounded-xl flex justify-center ">
                  <button className="p-1" onClick={() => setShowModal(false)}>
                    <img
                      className=" text-black h-7 w-7 outline-none focus:outline-none "
                      src={Images.closecircle}
                      alt=""
                    />
                  </button>
                </div>
              </div>
              {/*body*/}
              <form onSubmit={formik.handleSubmit} className="px-12 py-9">
                <div className="">
                  {articleEditInput.map((elm: any) =>
                    elm?.key === "option" ? (
                      <SelectComponent
                        id={elm?.name}
                        
                        handlechange={formik?.handleChange}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any)?.errors[elm.name]}
                        touched={(formik as any)?.touched[elm.name]}
                        option={Topics?.data?.data}
                      />
                    )
                    
                    : elm?.type == "file" ? (
                      <EditInputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={handleChangeOnFile}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any)?.errors[elm.name]}
                        touched={(formik as any)?.touched[elm.name]}
                      />
                    )
                    : (
                      <InputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={formik?.handleChange}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any)?.errors[elm.name]}
                        touched={(formik as any)?.touched[elm.name]}
                      />
                    )
                  )}
                </div>

                <div className="mt-9">
                  <button
                    className="w-[150px] h-[50px] border rounded border-aqua  text-aqua"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-[150px] h-[50px] border rounded bg-aqua text-white ml-6"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
              {/*footer*/}
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default EditArticle;
