import React, { useEffect } from "react";
import Images from "../../../constant/Images";
import InputComponent from "../../inputComponent/InputComponent";
import { AddTourInput, userEdit } from "../../../constant/string";
import services from "../../../services/services";
import { useAppDispatch } from "../../../store/app/store";
import { getallUsersThank } from "../../../store/features/getUsersSlice";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import SelectComponentUser from "../../selectComponentUser/SelectComponentUser";
import { getTourName } from "../../../services/Tour";

const UserEditModalComponent = ({ data }: any) => {
  const { adminPermission } = useSelector((state: any) => state.counter);
  const [showModal, setShowModal] = React.useState(false);
  const dispatch: any = useAppDispatch();
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [addTourConfirmation, setAddTourConfirmation] = React.useState(false);
  const [userTours, setUserTours] = React.useState<any>();
  const { Tours } = useSelector(
    (state: any) => state.tours
    );

  const userTourIds = userTours?.map((userTour:any) => userTour.tourId);
  const filteredTours = Tours?.data?.data.filter((tour: any) => {
    return !userTourIds?.includes(tour.productId);
  }).map((tour: any) => {
      return {
          ...tour,
          name: getTourName(tour?.name, "en") || '' // If English name exists, assign its text, otherwise empty string
      };
  });
  

  const validate = (values: any) => {
    const errors: any = {};


    if (!values?.firstName?.trim()) {
      errors.firstName = "This field is required";
    } else if (values?.firstName === "") {
      errors.firstName = "Can't edit empty value";
    }
    // else if (values?.firstName?.length > 15) {
    //   errors.firstName = "Must be 15 characters or less";
    // }

    if (values.constant && !values?.contact?.trim()) {
      errors.contact = "This field is required";
    }
    //  else if (values.contact.length > 20) {
    //   errors.lastName = "Must be 20 characters or less";
    // }

    if (!values.email?.trim()) {
      errors.email = "This field is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      firstName: data?.firstName,
      email: data?.email,
      contact: data?.contact,
      id: data?.id,
    },
    validate,
    onSubmit: (values) => {
      handleSave(values);
    },
    enableReinitialize: true,
  });
  const [userInfo, setUserInfo] = React.useState({
    firstName: data?.firstName,
    email: data?.email,
    contact: data?.contact,
    id: data?.id,
  });

  const tourFormik: any = useFormik({
    initialValues: {
      email: data?.email,
    },
    onSubmit: (values: any) => {
      const details = JSON.parse(tourFormik.values.cartDetails);
      const cartDetails =[ {
        productId: details.productId,
        destinationName: details.name,
        imagesUrl: details.imageUrl,
        placeId: details.placeId,
        status: 1,
        amount: details.sellingPrice,
        tourId: details.id,
        bundelsId: 1,
    }]; 
    const payload = { ...tourFormik.values, cartDetails}

    tourFormik?.handleReset(tourFormik.values);
    setAddTourConfirmation(false);
    handleAddTour(payload)
    },
  });

  const handlechange = (e: any, name: any) => {
    setUserInfo({ ...userInfo, [name]: e.target.value });
  };
  const editUserById = async (payload: any) => {
    const res = await services.editUser(payload);
    if ((res as any)?.status == 200) {
      toast.success((res as any).data.message);
      dispatch(getallUsersThank());
    } else {
      toast.error("Something went wrong");
    }

  };
  const handleSave = (payload: any) => {

    editUserById(payload);

  };

  const handleDeleteUser = async () => {
    try {
      const id = data.id
      setShowConfirmation(false);
      const response: any = await services.deleteUserApi(id);
      setShowModal(false)
      dispatch(getallUsersThank());
      toast.success("User Deleted Successfully!");
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Error Deleting User!');
    }
  };

  const handleAddTour = async (payload: any) => {
    try {
      const res = await services.createUserApi({...payload});
      if (res.status === true) {
        getUserTours(data.id)
        toast.success("Tour Added Successfully!");
      }
    } catch (error) {
      toast.error("Error Adding Tour!");
      console.log(error);
    }
  };

  const getUserTours = async (id : any) => {
    try {
      // const id = data.id
      const response: any = await services.userTourList(id);
      const allTours: any = await services.getAllTour();
      for (const item1 of response?.data) {
        for (const item2 of allTours?.data?.data) {
            if (item1.tourId === item2.productId) {
                if (!item1.destinationName) {
                    item1.destinationName = item2.name;
                }
            }
        }
    }
      setUserTours(response?.data)
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };
  useEffect(() => {
    if(showModal){
      getUserTours(data.id)
    }
  }, [showModal]);
  return (
    <>
      <img
        className="font-roboto font-medium text-xl  w-6 h-6 cursor-pointer"
        src={Images.edit}
        alt=""
        onClick={() => {
          if (adminPermission?.is_admin == 2) {
            if (adminPermission?.editUser) {
              setShowModal(true);
            } else {
              toast.dismiss();
              toast.error("You don't have permission to edit user");
            }
          } else {
            setShowModal(true);
          }
        }}
      />

      {showModal ? (
        <>
          <div className="justify-center items-center flex  fixed inset-0 z-50 outline-none  ">
            <div className="relative w-[900px] h-[550px] bg-white my-6 rounded  overflow-auto overflow-x-hidden">
              {/*content*/}

              {/*header*/}
              <div className="text-xl font-semibold bg-lightaqua w-[900px] h-[50px] flex justify-center items-center rounded">
                <h3 className="font-sans font-bold">Edit User</h3>
                <div className="bg-aquadark w-[50px] h-[50px] rounded-xl flex justify-center relative left-[23.6rem]">
                  <button className="p-1" onClick={() => setShowModal(false)}>
                    <img
                      className=" text-black h-7 w-7 outline-none focus:outline-none"
                      src={Images.closecircle}
                      alt=""
                    ></img>
                  </button>
                </div>
              </div>

              {/*body*/}
              <form onSubmit={formik.handleSubmit}>
                <div className="px-12 py-9">
                  {userEdit.map((elm: any) => (
                    <InputComponent
                      key={elm?.name}
                      id={elm?.name}
                      handlechange={formik.handleChange}
                      accept={elm?.accept}
                      value={(formik as any)?.values?.[elm?.name]}
                      name={elm?.name}
                      label={elm?.label}
                      placeholder={elm?.placeHolder}
                      type={elm?.type}
                      error={(formik as any).errors[elm.name]}
                      touched={(formik as any).touched[elm.name]}
                    />
                  ))}
                </div>
                <div className="px-12 py-5">
                  <div className="flex items-center justify-between mb-2 mt-3">
                    <h1 className="text-xl font-bold text-darkgray font-roboto">Purchased Tours</h1>
                    <button
                      className="w-[150px] h-[50px] border rounded bg-aqua text-white"
                      type="button"
                      onClick={() => {
                        setAddTourConfirmation(true);
                      }}
                    >
                      Add Tours
                    </button>
                  </div>
                  <ul className="list-decimal pl-6">
                    {userTours?.map((tour: any, index: number) => (
                      <li key={tour.destinationName} className="text-darkgray my-1">
                        {tour.destinationName}
                      </li>
                    ))}
                  </ul>
                </div>

                {/*footer*/}
                <button
                  className="w-[150px] h-[50px] border rounded border-aqua ml-11 text-aqua mb-9"
                  type="button"
                  onClick={() => {
                    formik.handleReset(formik.values);
                    setShowModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="w-[150px] h-[50px] border rounded bg-aqua text-white ml-6 mb-9"
                  type="submit"
                  onClick={() => {

                  }}
                >
                  Save
                </button>
                <button
                  className="w-[150px] h-[50px] border rounded bg-red-500 text-white ml-6 mb-9"
                  type="button"
                  onClick={() => {
                    setShowConfirmation(true)
                  }}
                >
                  Delete
                </button>

              </form>
            </div>
            {showConfirmation && (
              <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-lg">
                  <p className="text-lg font-semibold">Are you sure you want to delete?</p>
                  <div className="mt-6 flex justify-between">
                    <button
                      className="w-[100px] px-6 py-3 bg-red-500 text-white rounded-lg"
                      onClick={handleDeleteUser}
                    >
                      Yes
                    </button>
                    <button
                      className="w-[100px] px-6 py-3 border-2 rounded-lg"
                      onClick={() => setShowConfirmation(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            )}
            {addTourConfirmation && (
              <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-lg">
                  <form className="px-12 py-9" onSubmit={tourFormik.handleSubmit}>
                    <div className="">
                      {AddTourInput.map((elm: any) =>
                        <SelectComponentUser
                          id={elm?.name}
                          handlechange={tourFormik.handleChange}
                          value={(tourFormik as any)?.values?.[elm?.name]}
                          name={elm?.name}
                          label={elm?.label}
                          placeholder={elm?.placeHolder}
                          type={elm?.type}
                          error={(tourFormik as any).errors[elm.name]}
                          touched={(tourFormik as any).touched[elm.name]}
                          option={elm?.key1 == "tourId" ? filteredTours : elm?.option}
                        />
                      )}
                      <div>{/* <AddStopsComponent />{" "} */}</div>
                    </div>

                    <div className="mt-9">
                      <button
                        className="w-[150px] h-[50px] border rounded border-aqua  text-aqua"
                        type="button"
                        onClick={() => {
                          setAddTourConfirmation(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="w-[150px] h-[50px] border rounded bg-aqua text-white ml-6"
                        type='submit'
                        onClick={() => { }}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default UserEditModalComponent;
