import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../../services/services";
 

const initialState:any={
users:[],
isLoading:false,
isError:false,
isSuccess:false,
error:""
}
const getUsersSlice = createSlice({
    name :"getUsers",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getallUsersThank.pending,(state)=>({
          ...state,
          isLoading:true
        }))
        .addCase(getallUsersThank.fulfilled,(state:any,action)=>({
            ...state,
            isLoading:false,
            isSuccess:true,
            users:action.payload
          }))
          .addCase(getallUsersThank.rejected,(state:any,action)=>({
            ...state,
            isLoading:false,
            isError:true,
            error:action.payload
            
          }))
    }


})


export const getallUsersThank = createAsyncThunk("getUsers/getallUsersThank",async (_, { dispatch }) => {
  return await services.getUser();
}
)

 export default getUsersSlice.reducer