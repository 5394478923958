import React, { useEffect, useRef, useState } from "react";
import Images from "../../../constant/Images";
import { STORAGE_PATH, addPlaceinput } from "../../../constant/string";
import InputComponent from "../../inputComponent/InputComponent";
import services from "../../../services/services";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { PlacesList } from "../../../store/features/placesListSlice";
import { toast } from "react-toastify";
import EditInputComponent from "../../inputComponent/EditInputComponent";
import SelectComponent from "../../selectComponent/SelectComponent";

const PlaceEdit = ({ place }: any) => {
  const [file, setFile] = useState<any>({});
  const dispatch: any = useDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const [placeData, setPlaceData] = React.useState<any>({});

  const handleAddPlaces = async (payload: any) => {
    const res: any = await services.editPlace(place?.id, payload);

    if (res?.status == 200) {
      toast.success(res?.data.message);
      dispatch(PlacesList());
    } else {
      toast.dismiss();
      toast.error("Something Went Wrong");
    }
  };

  useEffect(() => {
    formik?.handleReset(formik.values);
    (formik as any).values = {};
    if (showModal) {
      (formik as any).values = {
        name: place?.name,
        thumbnailUrl: place?.thumbnailUrl,
        published: place?.published
      };
    }
  }, [showModal, place]);
  const formik = useFormik({
    initialValues: {
      name: place?.name,
      thumbnailUrl: place?.thumbnailUrl,
      published: place?.published
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required("This field is required"),
      thumbnailUrl: Yup.string().trim().required("This field is required"),
      published: Yup.boolean()?.required("This field is required"),
    }),
    onSubmit: (values) => {
      setShowModal(false);
      handleAddPlaces({
        ...values,
        thumbnailUrl: file?.thumbnailUrl?.replace(`${STORAGE_PATH}/imageUpload/`, ""),
        audioUrl: file?.audioUrl?.replace(`${STORAGE_PATH}/audioUpload/`, ""),
      });
    },
    enableReinitialize: true,
  });

  const handleChangeOnFile = async (e: any) => {
    if (e.target.accept == "image/*") {
      formik.values.thumbnailUrl = e.target.value;
      const res = await services.uploadImage(e.target.files[0]);

      if (res?.data?.data?.url[0]?.path) {
        setFile({
          ...file,
          [e.target.name]: res?.data?.data?.url[0]?.path?.replace(
            /\/root\/imageUpload\//g,
            ""
          ),
        });
        // formik.errors.thumbnailUrl = "";
      } else {
        formik.values.thumbnailUrl = "";
        formik.errors.thumbnailUrl = "Please select correct img";
      }
    }
  };

  return (
    <>
      <img
        className="font-roboto font-medium text-xl  w-6 h-6 cursor-pointer"
        src={Images.edit}
        alt=""
        onClick={() => setShowModal(true)}
      />
      {showModal ? (
        <>
          <div className="justify-center  box-border  items-center flex  fixed inset-0 z-50 outline-none">
            <div className="relative w-[950px] h-[600px] bg-white my-6 rounded overflow-y-auto ">
              {/*content*/}

              {/*header*/}
              <div className="flex justify-between text-xl font-semibold bg-lightaqua w-[100%] h-[70px] items-center rounded">
                <div></div>
                <h3 className="font-sans font-bold">Add Places</h3>
                <div className="bg-aquadark w-[70px] h-[70px] rounded-xl flex justify-center ">
                  <button className="p-1" onClick={() => setShowModal(false)}>
                    <img
                      className=" text-black h-7 w-7 outline-none focus:outline-none "
                      src={Images.closecircle}
                      alt=""
                    ></img>
                  </button>
                </div>
              </div>
              {/*body*/}

              <form className="px-12 py-9 " onSubmit={formik.handleSubmit}>
                <div className="">
                  {addPlaceinput.map((elm: any) =>
                  elm?.key == "option" ? (
                    <SelectComponent
                      id={elm?.name}
                      handlechange={formik.handleChange}
                      value={(formik as any)?.values?.[elm?.name]}
                      name={elm?.name}
                      label={elm?.label}
                      placeholder={elm?.placeHolder}
                      type={elm?.type}
                      error={(formik as any).errors[elm.name]}
                      touched={(formik as any).touched[elm.name]}
                      option={
                        elm?.option
                      }
                    />
                  ) :
                    elm?.type == "file" ? (
                      <EditInputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={handleChangeOnFile}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    ) : (
                      <InputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={formik.handleChange}
                        accept={elm?.accept}
                        value={
                          elm?.type == "file"
                            ? ""
                            : (formik as any)?.values?.[elm?.name]
                        }
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    )
                  )}
                </div>

                <div className="mt-9 ">
                  <button
                    className="w-[150px] h-[50px] border rounded border-aqua  text-aqua"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-[150px] h-[50px] border rounded bg-aqua text-white ml-6"
                    type="submit"
                    onClick={() => {}}
                  >
                    Save
                  </button>
                </div>
              </form>
              {/*footer*/}
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default PlaceEdit;
