import React from "react";
import HeaderComponent from "../component/header/HeaderComponent";
import SidebarComponent from "../component/sidebar/SidebarComponent";
import "./commanStyle.css";
const Layout =
  (Component: any) =>
  ({ ...props }) =>
    (
      <div className="flex w-full h-screen">
        <div className="w-1/5">
          <SidebarComponent />
        </div>
        <div className="w-4/5">
          <div className="header-calc">
            <HeaderComponent />
          </div>
          <div className="component-calc">
            <Component {...props} />
          </div>
        </div>
      </div>
    );

export default Layout;
