import React, { useEffect, useState } from "react";
import BookingsTableComponent from "../../component/bookingsTableComponent/BookingsTableComponent";
import { useSelector } from "react-redux";
import services from "../../services/services";

const Bookings = () => {
    const { adminPermission } = useSelector((state: any) => state.counter);
    const [allBooking, setAllBooking] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchBookings = async () => {
        try {
          const res = await services.getBookings();
          setAllBooking(res?.data?.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };

    useEffect(() => {
        fetchBookings()
    }, []);
    if (loading) {
        return (
          <div className="flex w-full justify-center mt-8">
            <h1 className="text-aqua text-3xl">Loading...</h1>
          </div>
        );
      }
    if (adminPermission?.is_admin == 2 && !adminPermission?.editUser) {
        return (
            <h1 className="text-3xl font-openSans font-bold text-aqua items-center flex justify-center h-[80vh]">
                {" "}
                You don't have permission
            </h1>
        );
    }
    return (
        <div>
            <div className="flex px-12 w-full justify-between">
            </div>
            <div className="mt-6">
                {allBooking?.length ? (
                    <BookingsTableComponent allUser={allBooking} />
                ) : (
                    <div className="w-full h-[20vh] flex justify-center items-center">
                        <h2 className="text-center text-aqua font-bold font-openSans w-full text-4xl">
                            {"No Bookings Available "}
                        </h2>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Bookings;
