import React, { useEffect } from "react";
import FormComponent from "../../component/form/FormComponent";
// import SelectComponent from "../../component/selectComponent/SelectComponent";

import Images from "../../constant/Images";
import { useAppDispatch } from "../../store/app/store";

import { useSelector } from "react-redux";
import { getAdminsThank } from "../../store/features/getAdminsSlice";

const Admin = () => {
  const { adminPermission } = useSelector((state: any) => state.counter);
  if (adminPermission?.is_admin == 2 && !adminPermission?.admin) {
    return (
      <h1 className="text-3xl font-openSans font-bold text-aqua items-center flex justify-center h-[80vh]">
        {" "}
        You don't have permission
      </h1>
    );
  }
  return (
    <div className="py-9">
      {/* <SelectComponent/> */}
      <FormComponent />
    </div>
  );
};

export default Admin;
