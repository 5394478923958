import React from "react";
import InputComponent from "../../component/inputComponent/InputComponent";
import Images from "../../constant/Images";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import services from "../../services/services";

const Setting = () => {
  const [openTab, setOpenTab] = React.useState(1);
  const color = "black";

  const passwordChange = async (payload: any) => {
    const res: any = await services.changePassword(payload);
    if (res?.response?.status == 401) {
      toast.dismiss();
      toast.error(res?.response?.data?.message);
    }
    if (res?.status == 200) {
      toast.dismiss();
      toast.success(res?.data.message);
    }
   
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().trim().required("This field is required"),
      newPassword: Yup.string().trim().required("This field is required") .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
        "Please choose a stronger password. Try a mix of letters, numbers, and symbols."
      ).min(8,"Password must be at least 8 characters long."),
      confirmPassword: Yup.string().trim().required("This field is required").matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
        "Please choose a stronger password. Try a mix of letters, numbers, and symbols."
      ).min(8,"Password must be at least 8 characters long."),
    }),
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      if (values?.newPassword !== values?.confirmPassword) {
        toast.dismiss();
        toast.error("Password does not match");
      }
      else if(values?.newPassword ===values?.currentPassword  ){
        toast.dismiss();
        toast.error("Current  and new password can not be same.");
      }
      else {
        passwordChange(values);
      }
     
  
    },
  });
  return (
    <div className="px-12 py-12">
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="border-b border-aqua w-full flex">
            <div className="w-2/4">
              <ul
                className="flex mb-0 list-none flex-wrap flex-row "
                role="tablist"
              >
                {/* <li className=" mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xl font-bold uppercase px-5 py-3 block leading-normal font-openSans" +
                      (openTab === 1
                        ? "text-blue border-b-4 border-aqua "
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(1);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    Basic Details
                  </a>
                </li> */}
                <li className=" mr-2 last:mr-0 flex-auto">
                  <a
                    className={
                      "text-xl font-bold uppercase py-3 block leading-normal font-openSans" +
                      (openTab === 2 || openTab === 1
                        ? "text-blue border-b-4 border-aqua "
                        : "text-" + color + "-600 bg-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(2);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    Change Password
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className=" ">
            <div className="mt-9 flex-auto">
              <div className="tab-content tab-space mt-2">
                {/* <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <div>
                    <div className="flex justify-between">
                      <h5 className="text-xl text-black font-openSans font-bold">
                        Name :{" "}
                      </h5>
                      <img src={Images.edit} />
                    </div>
                    <p className="text-base text-blue font-openSans font-normal">
                      Admin{" "}
                    </p>
                  </div>
                  <div className="mt-2">
                    <h5 className="text-xl text-black font-openSans font-bold">
                      Email ID :{" "}
                    </h5>
                    <p className="text-base text-blue font-openSans font-normal">
                      pj@gmail.com
                    </p>
                  </div>
                  <div className="mt-2">
                    <h5 className="text-xl text-black font-openSans font-bold">
                      Profile Image:{" "}
                    </h5>
                    <p className="text-base text-blue font-openSans font-normal">
                      image
                    </p>
                  </div>
                  <div className="mt-2">
                    <h5 className="text-xl text-black font-openSans font-bold">
                      Mobile Number :{" "}
                    </h5>
                    <p className="text-base text-blue font-openSans font-normal">
                      23998473788998
                    </p>
                  </div>
                </div> */}
                <form
                  onSubmit={formik.handleSubmit}
                  className={"block"}
                  id="link2"
                >
                  <InputComponent
                    label="Enter Old Password"
                    placeholder="Enter old password"
                    id={"currentPassword"}
                    handlechange={formik.handleChange}
                    value={(formik as any)?.values?.currentPassword}
                    name={"currentPassword"}
                    type={"password"}
                    error={(formik as any).errors?.currentPassword}
                    touched={(formik as any).touched?.currentPassword}
                  />
                  <InputComponent
                    label="Enter New Password"
                    placeholder="Enter new password"
                    id={"newPassword"}
                    handlechange={formik.handleChange}
                    value={(formik as any)?.values?.newPassword}
                    name={"newPassword"}
                    type={"password"}
                    error={(formik as any).errors?.newPassword}
                    touched={(formik as any).touched?.newPassword}
                  />
                  <InputComponent
                    label="Confirm New Password"
                    placeholder="Confirm new password"
                    id={"confirmPassword"}
                    handlechange={formik.handleChange}
                    value={(formik as any)?.values?.confirmPassword}
                    name={"confirmPassword"}
                    type={"password"}
                    error={(formik as any).errors?.confirmPassword}
                    touched={(formik as any).touched?.confirmPassword}
                  />
                  <div className="mt-7">
                    <button
                      className="w-[150px] h-[50px] border rounded border-aqua  text-aqua"
                      type="button"
                      onClick={() => {
                        formik.handleReset(formik.values)
                        }}
                    >
                      Cancel
                    </button>
                    <button
                      className="w-[150px] h-[50px] border rounded bg-aqua text-white ml-6"
                      type="submit"
                  
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
