import React, { useEffect, useState } from "react";
import "./inputStyle.css";
import { languages } from "../../constant/Languages";
import services from "../../services/services";
import { STORAGE_PATH } from "../../constant/string";
import { getStringOrParsedJson } from "../../services/Tour";
const LanguageInputComponentText = ({
  label,
  value,
  placeholder,
  type,
  name,
  handlechange,
  error,
  touched,
  handleBlur,
  id,
  accept,
  handleSelectedLanguages
}: any) => {
  const [textInput, setTextInput] = useState<any>([])
  const [textLanguage, setTextLanguage] = useState<any>([]);
  const [newLanguage, setNewLanguage] = useState<string>("");
  const [selectedLanguage,setSelectedLanguage] = useState<any>()
  const [showInputField, setShowInputField] = useState<boolean>(false);


  const handleLanguageChange = (index: any, language: any) => {
    const updatedLanguages = [...textLanguage];
    updatedLanguages[index] = language;
    setTextLanguage(updatedLanguages);
  };
  const handleFileChange = (index: any, value: any) => {
    const newText = value
    const updatedTextInput = [...textInput];
    updatedTextInput[index] = newText;
    setTextInput(updatedTextInput);
  }

  const removeHandler = (index: any) => {
    const updatedText = [...textInput];
    const updatedLanguages = [...textLanguage];

    updatedText.splice(index, 1);
    updatedLanguages.splice(index, 1);

    setTextInput(updatedText);
    setTextLanguage(updatedLanguages);

    const combinedData: any[] = updatedText?.reduce(
      (result: any, text: any, index: any) => {
        result[index + 1] = updatedLanguages[index];
        return result;
      },
      []
    );
    handlechange(combinedData);
  };

  const handleAddLanguage = () => {
    setTextInput([...textInput, newLanguage]);
    setNewLanguage("");
    setShowInputField(false);
  };

  const handleAdd = () => {
    setShowInputField(true);
  };

  const handleLanguageSelect = (selectedLanguage: string) => {
    setTextLanguage([...textLanguage, selectedLanguage]);
    handleAddLanguage()
  };

  useEffect(() => {
    let combinedData = [];
    combinedData = textInput.map((path: any, index: any) => {
      const language = textLanguage[index];
      const text = textInput[index]
      return { language, text };
    });
    handlechange(combinedData);
  }, [textInput, textLanguage]);
  useEffect(()=>{
    if(handleSelectedLanguages){
    handleSelectedLanguages(textLanguage)
    }
  },[textLanguage])
  useEffect(() => {
    const fetchData = async()=>{ 
    if (name && name !="name") {
      const parsed =getStringOrParsedJson(name)
      const mappedText =await parsed?.map((item: any) => item.text);
      const mappedLanguages =await parsed?.map((item: any) => item.language);
      setTextInput(mappedText);
      setTextLanguage(mappedLanguages);
    }
  }
  fetchData()
  }, [name]);


  return (
    <div className="">
      <label className="block mb-2 mt-3 text-xl font-bold text-darkgray font-roboto items-start flex">
        {label}
      </label>
      <div className="bg-gray-50 border font-roboto font-normal text-black border-slate-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none">
        <div>
          {textInput.map((file: any, index: any) => (
            <div key={index} className="flex items-center mb-2">
              <span className="mr-2">{index + 1}.</span>
              <span className="px-2 py-1  border-1 m-1 font-roboto font-normal">
                <input type="text" className="border rounded-md border-slate-300" value={file} onChange={(e)=>handleFileChange(index, e.target.value)}/>
              </span>
              <select
                value={textLanguage[index]}
                onChange={(e) => handleLanguageChange(index, e.target.value)}
                className="bg-gray-50 border border-slate-300 text-gray-900 text-sm rounded-lg p-1 outline-none"
              >
                <option value="">Select Language</option>
                {languages?.map((elm) => (
                  <option key={elm.code} value={elm.code}>
                    {elm.name}
                  </option>
                ))}
              </select>
              <button
                onClick={() => removeHandler(index)}
                type={"button"}
                className="ml-2 px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none"
              >
                Remove
              </button>
            </div>
          ))}
        </div>

        {showInputField && (
          <div className="flex items-center mb-2">
            <input
              type="text"
              value={newLanguage}
              onChange={(e) => setNewLanguage(e.target.value)}
              className="bg-gray-50 border border-slate-300 text-gray-900 text-sm rounded-lg p-1 outline-none"
              placeholder="Add New Language"
            />
            <select
                value={selectedLanguage}
                onChange={(e) => {handleLanguageSelect(e.target.value);}}
                className="bg-gray-50 border border-slate-300 text-gray-900 text-sm rounded-lg p-1 outline-none"
              >
                <option value="">Select Language</option>
                {languages?.map((elm) => (
                  <option key={elm.code} value={elm.code}>
                    {elm.name}
                  </option>
                ))}
              </select>
            <button
                onClick={() => setShowInputField(false)}
                type={"button"}
                className="ml-2 px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none"
              >
                Remove
              </button>
          </div>
        )}

        <label htmlFor={name} className="font-roboto font-normal">
          <span className="px-2 py-1 bg-lightaqua border-2 m-1 font-roboto font-normal cursor-pointer">
            <button type="button" onClick={handleAdd}>+ Add More Languages</button>

          </span>
        </label>
      </div>
    </div>
  );
};

export default LanguageInputComponentText;
