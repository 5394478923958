import { useEffect, useState } from "react";
import SearchComponent from "../../component/search/SearchComponent";
import TourscardComponent from "../../component/tours/TourscardComponent";
import TourModalComponent from "../../component/places/modal/TourModalComponent";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/app/store";
import { PlacesList } from "../../store/features/placesListSlice";

const Tours = () => {
  const [isUpdate, setIsUpdate] = useState(false);
  const dispatch: any = useAppDispatch();
  const { Tours, isSuccess } = useSelector((state: any) => state.tours);
  const [allTour, setAllTour] = useState([]);
  const [filterData, setFilterData] = useState<any>();
  const [searchValue, setSearchValue] = useState();
  useEffect(() => {
    // if (!places?.data?.data?.data?.length) {
    dispatch(PlacesList());
    // }
  }, []);
  const getSearchData = (data: any, value: any) => {
    setSearchValue(value);
    setFilterData(data);
  };
  useEffect(() => {
    // dispatch(toursThank());
    isSuccess && setAllTour(Tours?.data?.data);
  }, []);
  const { adminPermission } = useSelector((state: any) => state.counter);
  if (adminPermission?.is_admin == 2 && !adminPermission?.tours) {
    return (
      <h1 className="text-3xl font-openSans font-bold text-aqua items-center flex justify-center h-[80vh]">
        You don't have permission
      </h1>
    );
  }
  return (
    <>
      <div>
        <div className="flex px-12 w-full justify-between">
          <div className="w-3/4">
            <SearchComponent
              getSearchData={getSearchData}
              data={Tours?.data?.data}
            />
          </div>
          <div className="flex w-1/4 justify-end mr-4">
            <TourModalComponent />
            {/* <DropDownComponent /> */}
          </div>
        </div>
        <div className=" grid  gap-6 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 mt-9 mx-14 mb-6 max-h-[600px] min-h-[600px]">
          {filterData?.length
            ? filterData?.map((elm: any, index: number) => (
                <TourscardComponent key={index} allTour={elm} />
              ))
            : Tours?.data?.data &&
              !searchValue &&
              Tours?.data?.data?.map((elm: any, index: number) => (
                <TourscardComponent key={index} allTour={elm} />
              ))}
        </div>
      </div>
      {!filterData?.length && searchValue ? (
        <div className="w-full h-[60vh] flex justify-center items-center">
          <h2 className="text-center text-aqua font-bold font-openSans text-4xl">
            {" "}
            {searchValue} is not available
          </h2>
        </div>
      ) : null}
    </>
  );
};

export default Tours;
