import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import _ from "lodash"
import "react-quill/dist/quill.snow.css";
import { languages } from "../../constant/Languages";
import { getStringOrParsedJson } from "../../services/Tour";

const RichTextEditor = ({
  label,
  value,
  handlechange,
  placeholder,
  discription,
  choosenLanguage
}: any) => {
  const [descriptionInput, setDescriptionInput] = useState<any>([])
  const [descriptionLanguage, setDescriptionLanguage] = useState<any>([]);

  const handleFileChange = _.debounce((index: any, value: any) => {
    const newText = value
    const updatedDescriptionInput = [...descriptionInput];
    updatedDescriptionInput[index] = newText;
    setDescriptionInput(updatedDescriptionInput);
  }, 500)


  useEffect(()=>{
    if(choosenLanguage && choosenLanguage.length > 0){
      for(let lang of choosenLanguage){
        if(!descriptionLanguage.includes(lang)){
          descriptionLanguage.push(lang)
        descriptionInput.push("")
        }
      } 
    } 
      const removedIndex = descriptionLanguage.findIndex((element:any) => !choosenLanguage.includes(element));
      if(removedIndex != -1){
        descriptionInput.splice(removedIndex, 1);
        descriptionLanguage.splice(removedIndex, 1)
      }
  },[choosenLanguage])

  useEffect(() => {
    let combinedData = [];
    combinedData = descriptionInput.map((path: any, index: any) => {
      const language = descriptionLanguage[index];
      const text = descriptionInput[index]
      return { language, text };
    });
    handlechange(combinedData);
  }, [descriptionInput, descriptionLanguage,choosenLanguage]);

  useEffect(() => {
    const fetchData = async()=>{
      if (discription) {
        const parsed =getStringOrParsedJson(discription)
        const mappedDiscription=await parsed?.map((item: any) => item.text);
        const mappedLanguages =await parsed?.map((item: any) => item.language);
        setDescriptionInput(mappedDiscription);
        setDescriptionLanguage(mappedLanguages);
      }
    }
    fetchData()
  }, [discription]);


  return (
    <>

      <h1 className="mb-2 mt-3 text-xl font-bold text-darkgray font-roboto justify-start">
        {label}
      </h1>
      <div className="border border-slate-300 p-3 rounded-md">

        {descriptionInput.map((language: any, index: number) => (
          <div key={`${index}`} className="mb-4">
            <span className="mr-2">{index + 1}.</span>
            <ReactQuill
              theme="snow"
              value={descriptionInput[index]}
              onChange={(value: string) => handleFileChange(index, value)}
              placeholder={placeholder}
            />
            <div className="m-1 flex justify-end">
              <h2 className="bg-gray-50 border border-slate-300 text-gray-900 text-sm rounded-lg p-1 outline-none">
              {languages
              .filter(elm => elm.code === descriptionLanguage[index])
              .map(filteredLang => (
              filteredLang.name
                ))}
              </h2>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default RichTextEditor;
