import React from "react";
import Images from "../../../constant/Images";

const DeleteModalComponent = ({ dlFn }: any) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <img
        className="font-roboto font-medium text-xl ml-3.5 w-6 h-6 cursor-pointer"
        src={Images.remove}
        alt=""
        onClick={() => setShowModal(true)}
      />
      {showModal ? (
        <>
          <div className="justify-center  items-center flex  fixed inset-0 z-50 outline-none ">
            <div className="relative  bg-white py-6 rounded px-3 w-80 ">
              {/*content*/}

              {/*header*/}
              {/* <div className="text-xl font-semibold bg-lightaqua w-[400px] h-[70px] flex justify-center items-center rounded">
              <h3 className="font-sans font-bold">Add Place</h3>
              <div className="bg-aquadark  rounded-xl flex justify-center relative right-0">
                <button className="p-1" onClick={() => setShowModal(false)}>
                  <img
                    className=" text-black h-7 w-7 outline-none focus:outline-none"
                    src={Images.closecircle}
                    alt=""
                  ></img>
                </button>
              </div>
            </div> */}

              {/*body*/}

              <div className="flex justify-between ">
                <p></p>
                {/* <h5 className="text-xl text-dimblack font-bold">Delete </h5> */}
                <button className="p-1" onClick={() => setShowModal(false)}>
                  {/* <img
                    className=" text-black h-5 w-5 outline-none focus:outline-none"
                    src={Images.closecircle}
                    alt=""
                  ></img> */}
                </button>
              </div>
              <div className="text-center">
                <p className="text-xl font-roboto font-normal">Are you sure you want to delete this?</p>
              </div>
              {/*footer*/}
              <div className="mt-4 flex justify-center">
              <button
                  className=" border rounded bg-aqua text-white  px-4 py-1"
                  type="button"
                  onClick={() => {
                    dlFn();
                    setShowModal(false);
                  }}
                >
                  Yes
                </button>
                <button
                  className="border rounded border-aqua text-aqua px-4 py-1 ml-6"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  No
                </button>
               
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default DeleteModalComponent;
