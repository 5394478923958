import { useState } from "react";
import Images from "../../constant/Images";
import InputComponent from "../../component/inputComponent/InputComponent";
import { resetpassword } from "../../constant/string";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../store/app/hooks";
import { useNavigate } from "react-router-dom";
import services from "../../services/services";
import setUpInterceptor from "../../services/Intercepter";
import { useSelector } from "react-redux";
import * as Yup from "yup";

const ResetPassword = () => {
  const counter = useSelector((state:any)=>state.counter)
  const admin = useSelector((state: any) => state.counter);
  const [isLogIn, setIsLogIn] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleLogin = async (payload: any) => {
    const res: any = await services.resetPassword(payload ,counter?.adminDetails?.uid );
    if ((res as any)?.status == 200) {
     
      setUpInterceptor(counter?.adminDetails?.uid)
      toast.success(res?.data?.message);
      navigate("/");
    } else {
      
      res
        ? toast.error(res.response.data.message)
        : res?.response?.data?.message
        ? toast.error(res?.response?.data?.message)
        : toast.error("something went wrong");
      // setTimeout(() => {
      //   setIsLogIn(false);
      // }, 5000);
    }
  };
  const validate = (values: any) => {
    const errors: any = {};

    if (!values.newPassword) {
      errors.newPassword = "This Field Is Required";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "This Field Is Required";
    }

    //   if (!values.email) {
    //     errors.email = "This Field Is Required";
    //   } else if (
    //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    //   ) {
    //     errors.email = "Invalid email address";
    //   }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validate,
    onSubmit: (values) => {
      setIsLogIn(true);
      handleLogin(values);
      if (values?.newPassword !== values?.confirmPassword) {
        toast.dismiss();
        toast.error("Password does not match");
      }
      // else(values?.newPassword ===values?.currentPassword){
      //   toast.dismiss();
      //   toast.error("Current and new password can not be same.");
      // }
      // alert(JSON.stringify(values, null, 2));
    },
    validationSchema: Yup.object({
      // currentPassword: Yup.string().trim().required("This field is required"),
      newPassword: Yup.string().trim().required("This field is required") .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
        "Please choose a stronger password. Try a mix of letters, numbers, and symbols."
      ).min(8,"Password must be at least 8 characters long."),
      confirmPassword: Yup.string().trim().required("This field is required").matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
        "Please choose a stronger password. Try a mix of letters, numbers, and symbols."
      ).min(8,"Password must be at least 8 characters long."),
    }),
  });
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="flex flex-col justify-center items-center w-1/3 m-auto ">
        <img src={Images.logo} alt="" />
        <h3 className="font-bold font-ProximaNovaAltBold text-7xl xl:text-6xl mt-11">
          Reset Password
        </h3>
        <form className="w-full mt-11" onSubmit={formik.handleSubmit}>
          {resetpassword.map((elm: any, id: number) => (
            <InputComponent
              type={elm.type}
              name={elm.name}
              key={elm.name + id}
              handleBlur={formik.handleBlur}
              touched={(formik as any).touched[elm.name]}
              label={elm.label}
              handlechange={formik.handleChange}
              value={(formik as any)?.values?.[elm?.name]}
              error={(formik as any).errors?.[elm.name]}
              placeholder={elm.placeholder}
            />
          ))}

          <button
            className="bg-aqualight w-full mt-12 py-4 rounded-md text-white font-bold font-ProximaNovaAltBold"
            type="submit"
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
