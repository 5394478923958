import React, { useEffect, useState } from "react";
import InputComponent from "../inputComponent/InputComponent";

import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import services from "../../services/services";

const VideosComponent = () => {
  const [data, setData] = useState<any>();
  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || "",
      url: data?.url || "",
    },
    validationSchema: Yup.object({
      url: Yup.string().trim().required("This field is required"),
    }),
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const onSubmit = async (values: any) => {
    try {
      const res = data?.id
        ? await services.updateVideo(values)
        : await services.createVideo(values);
      if (res?.data?.status) {
        toast.success("Data saved successfully!");
      } else {
        toast.error("Something went wrong");
      }
    } catch (err) {}
  };

  const getdata = async () => {
    try {
      const res = await services.getVideoById(1);
      setData(res?.data?.data);
    } catch (err) {}
  };

  useEffect(() => {
    (async () => {
      await getdata();
    })();
  }, []);

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full border border-aqua rounded-xl px-12 py-11"
      >
        <p className="font-bold font-sans text-xl">Video</p>
        <div className="w-full  mx-auto mt-8">
          <InputComponent
            id={"url"}
            handlechange={formik.handleChange}
            value={(formik as any)?.values?.url}
            name={"url"}
            label={"Video URL"}
            placeholder={"Video URL"}
            type={"text"}
            error={(formik as any).errors.url}
            touched={(formik as any).touched.url}
          />
        </div>

        <button
          className="w-[200px] h-[50px]  bg-aqua rounded  text-brown mt-10"
          type="submit"
          onClick={() => {}}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default VideosComponent;
