import React, { useEffect, useState } from "react";
import Images from "../../../constant/Images";
import InputComponent from "../../inputComponent/InputComponent";
import SelectComponent from "../../selectComponent/SelectComponent";
import { STORAGE_PATH, addTourinput } from "../../../constant/string";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import services from "../../../services/services";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../store/app/store";
import { toursThank } from "../../../store/features/toursSlice";
import EditInputComponent from "../../inputComponent/EditInputComponent";
import RichTextEditor from "../../RichTextEditor";
import LanguageInputComponent from "../../inputComponent/LanguageInputComponent";
import LanguageInputComponentText from "../../inputComponent/LanguageInputComponentText";
import { getTourName } from "../../../services/Tour";
import EmailInputComponent from "../../inputComponent/EmailInputComponent";

type NewTemplateObj = {
  language: string;
  isRendering: boolean;
  text: string;
  error: any;
};
const EditTourModal = ({ data }: any) => {
  const dispatch: any = useAppDispatch();
  const [File, setFile] = useState<any>({});
  const places = useSelector((state: any) => state.placesList);
  const { adminDetails } = useSelector((state: any) => state.counter);
  const [showModal, setShowModal] = React.useState(false);
  const [language, setLanguage] = useState<any>({});
  const [descriptionLanguage, setDescriptionLanguage] = useState<any>({});
  const [choosenLanguage, setChoosenLanguage] = useState<any>([]);

  const setTemplateData = (
    updater:
      | NewTemplateObj[]
      | ((prevData: NewTemplateObj[]) => NewTemplateObj[])
  ) => {
    console.log("inised settemplate", updater, typeof updater);
    if (typeof updater === "function") {
      let value = updater(formik.values.bookingEmailTemplate);
      formik.setFieldValue("bookingEmailTemplate", value);
    } else {
      formik.setFieldValue("bookingEmailTemplate", updater);
    }
  };

  const handleAddTour = async (payload: any) => {
    const res: any = await services.editTous(data?.id, payload);

    if (res?.status === 200) {
      toast.success(res?.data.message);
      dispatch(toursThank());
    } else {
      toast.dismiss();
      toast.error("Something Went Wrong");
    }
  };

  const validateAllTemplate = (templates: NewTemplateObj[]) => {
    return templates.map((item) => {
      const doc = new DOMParser().parseFromString(item.text, "text/html");
      const isError = doc.querySelector("parsererror");
      return isError
        ? { ...item, text: "" }
        : { ...item, text: doc.documentElement.outerHTML };
    });
  };

  const intializeTemplate = () => {
    const temp = data?.bookingEmailTemplate
      ? JSON.parse(data.bookingEmailTemplate)
      : [];
    const newTemplate = temp.map((item: any) => ({
      ...item,
      isRendering: false,
      error: "",
    }));
    return newTemplate;
  };
  const formik: any = useFormik({
    initialValues: {
      userId: adminDetails?.adminId,
      placeId: data?.placeId,
      name: data?.name,
      productId: data?.productId,
      imageUrl: data?.imageUrl,
      thumbnailUrl: data?.thumbnailUrl,
      // audioUrl: data?.audioUrl,
      videoUrl: data.videoUrl,
      type: data?.type,
      duration: data?.duration,
      description: data?.description,
      title: data?.title,
      retailPrice: data?.retailPrice,
      sellingPrice: data?.sellingPrice,
      reviewCount: data?.reviewCount,
      carRentalPerPerson: data?.carRentalPerPerson,
      narrationPoints: data?.narrationPoints,
      distance: data?.distance,
      published: data?.published,
      bokunId: data?.bokunId,
      stopLanguagesMap: data?.stopLanguagesMap,
      bookingEmailTemplate: intializeTemplate(),
    },
    validationSchema: Yup.object({
      placeId: Yup.string().trim().required("This field is required"),
      // name: Yup.string().trim().required("This field is required"),
      productId: Yup.string()
        .trim()
        .required("This field is required")
        .test("no-spaces", "Name cannot contain spaces", (value: any) => {
          return value && !/\s/.test(value);
        }),
      imageUrl: Yup.string().trim().required("This field is required"),
      thumbnailUrl: Yup.string().trim().required("This field is required"),
      // audioUrl: Yup.string().trim().required("This field is required"),
      videoUrl: Yup.string().trim().required("This field is required"),
      type: Yup.string().trim().required("This field is required"),
      duration: Yup.string().required("This field is required"),
      // description: Yup.string().trim().required("This field is required"),
      title: Yup.string().trim().required("This field is required"),
      bookingEmailTemplate: Yup.array()
        .min(1, "At least one template is required")
        .required("This field is required"),
      retailPrice: Yup.number().notOneOf(
        [0],
        "Zero and negative values not accepted"
      ),
      sellingPrice: Yup.number()
        .typeError("Please enter number value only")
        .nullable()
        .notOneOf([0], "Zero and negative values not accepted")
        // .required("Required")
        .min(0)
        .moreThan(-1, "Negative values not accepted")
        .test(
          "match",
          "SellingPrice is must be less than RetailPrice",
          function (sellingPrice: any) {
            return sellingPrice <= this.parent.retailPrice;
          }
        ),
      reviewCount: Yup.number()
        .typeError("Please enter number value only")
        .nullable()
        .required("This field is required")
        .min(0)
        .moreThan(-1, "Negative values not accepted"),

      carRentalPerPerson: Yup.number()
        .typeError("Please enter number value only")
        .nullable()
        .required("This field is required")
        .min(0)
        .moreThan(-1, "Negative values not accepted"),
      narrationPoints: Yup.number().min(0).required("This field is required"),
      distance: Yup.number().min(0).required("This field is required"),
      published: Yup.boolean()?.required("This field is required"),
      bokunId: Yup.number(),
    }),
    onSubmit: (values) => {
      setShowModal(false);
      formik?.handleReset(formik.values);

      //if try to save without rendering we need to verify html
      const validatedTemplates = validateAllTemplate(
        values.bookingEmailTemplate
      );

      const templateSave = validatedTemplates.map((item: NewTemplateObj) => ({
        text: item.text,
        language: item.language,
      }));

      const updatedValues = {
        ...values,
        bookingEmailTemplate: JSON.stringify(templateSave),
        thumbnailUrl: File?.thumbnailUrl?.replace(
          `${STORAGE_PATH}/imageUpload/`,
          ""
        ),
        imageUrl: File?.imageUrl?.replace(`${STORAGE_PATH}/imageUpload/`, ""),
        audioUrl: File?.audioUrl?.replace(`${STORAGE_PATH}/audioUpload/`, ""),
        name: language,
        description: descriptionLanguage,
      };
      handleAddTour(updatedValues);
    },
    enableReinitialize: true,
  });
  const handleChangeOnFile = async (e: any) => {
    if (e.target.accept === "image/*") {
      formik.values[e.target.name] = e.target.value;
      const res = await services.uploadImage(e.target.files[0]);

      if (res?.data?.data?.url[0]?.path) {
        setFile({
          ...File,
          [e.target.name]: res?.data?.data?.url[0]?.path?.replace(
            /\/root\/imageUpload\//g,
            ""
          ),
        });
        formik.values[e.target.name] = res?.data?.data?.url[0]?.path?.replace(
          /\/root\/imageUpload\//g,
          ""
        );
        // formik.errors[e.target.name] = null
      } else {
        formik.values[e.target.name] = "";
        formik.errors[e.target.name] = "Please select correct img";
      }
    }
    if (e.target.accept === "audio/*") {
      formik.values.audioUrl = e.target.value;
      const res = await services.uploadAudio(e.target.files[0]);

      if (res?.data?.data?.url[0]?.path) {
        setFile({
          ...File,
          [e.target.name]: res?.data?.data?.url[0]?.path?.replace(
            /\/root\/audioUpload\//g,
            ""
          ),
        });
        // formik.errors.audioUrl = "";
      } else {
        formik.values.audioUrl = "";
        formik.errors.audioUrl = "Please select correct img";
      }
    }
  };
  const deleteFile = async () => {
    setFile({
      ...File,
      thumbnailUrl: "",
    });
    formik.setFieldValue("thumbnailUrl", "");
  };
  const deleteImageUri = async () => {
    setFile({
      ...File,
      imageUrl: "",
    });
    formik.setFieldValue("imageUrl", "");
  };

  useEffect(() => {
    formik?.handleReset(formik.values);
    if (showModal) {
      (formik as any).values = {
        userId: adminDetails?.adminId,
        placeId: data?.placeId,
        name: data?.name,
        productId: data?.productId,
        imageUrl: data?.imageUrl,
        thumbnailUrl: data?.thumbnailUrl,
        // audioUrl: data?.audioUrl,
        bookingEmailTemplate: intializeTemplate(),
        videoUrl: data.videoUrl,
        type: data?.type,
        duration: data?.duration,
        description: data?.description,
        title: data?.title,
        retailPrice: data?.retailPrice,
        sellingPrice: data?.sellingPrice,
        reviewCount: data?.reviewCount,
        carRentalPerPerson: data?.carRentalPerPerson,
        narrationPoints: data?.narrationPoints,
        distance: data?.distance,
        published: data?.published,
        bokunId: data?.bokunId,
      };
    }
  }, [showModal]);

  return (
    <>
      <img
        src={Images.edit}
        alt=""
        onClick={() => setShowModal(true)}
        className="font-roboto font-medium text-xl w-6 h-6 cursor-pointer"
      />
      {showModal ? (
        <>
          <div className="justify-center items-center flex  fixed inset-0 z-50 outline-none">
            <div className="relative w-[900px] h-[600px] bg-white my-6 rounded overflow-y-auto overflow-x-hidden">
              {/*content*/}
              {/*header*/}
              <div className="flex justify-between text-xl font-semibold bg-lightaqua w-[100%] h-[70px] items-center rounded">
                <div></div>
                <h3 className="font-sans font-bold">Add Tours</h3>
                <div className="bg-aquadark w-[70px] h-[70px] rounded-xl flex justify-center ">
                  <button className="p-1" onClick={() => setShowModal(false)}>
                    <img
                      className=" text-black h-7 w-7 outline-none focus:outline-none "
                      src={Images.closecircle}
                      alt=""
                    ></img>
                  </button>
                </div>
              </div>

              {/*body*/}

              <form className="px-12 py-9" onSubmit={formik.handleSubmit}>
                <div className="">
                  {addTourinput.map((elm: any) =>
                    elm?.key === "option" ? (
                      <SelectComponent
                        id={elm?.name}
                        handlechange={formik.handleChange}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                        option={
                          elm?.key1 === "placeId"
                            ? places?.data?.data?.data
                            : elm?.option
                        }
                      />
                    ) : elm?.type === "file" ? (
                      <EditInputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={handleChangeOnFile}
                        deleteFile={deleteFile}
                        deleteImageUri={deleteImageUri}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    ) : elm?.type === "description" ? (
                      <RichTextEditor
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={(data: any) => {
                          const updatedData = data?.map((item: any) => {
                            return {
                              language: item?.language,
                              text: item?.text,
                            };
                          });
                          setDescriptionLanguage(updatedData);
                        }}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        discription={data?.description}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        touched={(formik as any).touched[elm.name]}
                        choosenLanguage={choosenLanguage}
                        setchoosenLanguageToEmpty={() => {
                          setChoosenLanguage((prev: any) => prev.slice(0, -1));
                        }}
                      />
                    ) : elm?.name === "name" ? (
                      <>
                        <LanguageInputComponentText
                          key={elm?.name}
                          id={elm?.name}
                          handlechange={(data: any) => {
                            const updatedData = data?.map((item: any) => {
                              return {
                                language: item?.language,
                                text: item?.text,
                              };
                            });
                            setLanguage(updatedData);
                          }}
                          handleSelectedLanguages={(data: any) => {
                            setChoosenLanguage((prev: any) => [...data]);
                          }}
                          accept={elm?.accept}
                          value={(formik as any)?.values?.[elm?.name]}
                          name={data?.name}
                          label={elm?.label}
                          placeholder={elm?.placeHolder}
                          type={elm?.type}
                          error={(formik as any).errors[elm.name]}
                          touched={(formik as any).touched[elm.name]}
                        />
                      </>
                    ) : elm?.type === "email" ? (
                      <>
                        <EmailInputComponent
                          key={elm?.name}
                          templateData={formik.values.bookingEmailTemplate}
                          setTemplateData={setTemplateData}
                          name={elm?.name}
                          label={elm?.label}
                          placeholder={elm?.placeHolder}
                          err={(formik as any).errors[elm.name]}
                          touched={(formik as any).touched[elm.name]}
                        />
                      </>
                    ) : (
                      <InputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={formik.handleChange}
                        accept={elm?.accept}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    )
                  )}
                  <div>{/* <AddStopsComponent />{" "} */}</div>
                </div>

                <div className="mt-9">
                  <button
                    className="w-[150px] h-[50px] border rounded border-aqua  text-aqua"
                    type="button"
                    onClick={() => {
                      formik.handleReset(formik.values);
                      setShowModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-[150px] h-[50px] border rounded bg-aqua text-white ml-6"
                    type="submit"
                    onClick={() => {}}
                  >
                    Save
                  </button>
                </div>
              </form>
              {/*footer*/}
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default EditTourModal;
