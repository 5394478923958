import instance, { BaseUrl } from "./InstanceAxios";
import axios from "axios";
const getAllPlace = async () => {
  const res = await instance.get("/api/places/list");

  return res;
};
const getAllTour = async () => {
  const res = await instance.get("/api/tours/list");
  return res;
};
const getAllFaq = async () => {
  const res = await instance.get(`/api/faq/list`);
  return res;
};
const postAddPlace = async (placeData: any) => {
  const res = await instance.post("/api/places/create", placeData);
  return res;
};
const postAddFaq = async (faqData: any) => {
  const res = await axios(`${BaseUrl}/api/faq/create`, {
    // await instance.post("/api/faq/create",{
    method: "post",
    data: {
      tourId: faqData?.tourId,
      name: faqData?.filterName,
      question: [
        {
          topQuestion: faqData?.addQuestion,
          description: faqData?.addAnswer,
        },
      ],
    },
  });

  return res;
};
// delete faq
const removeAddFaq = async (id: any) => {
  const res = await axios(`${BaseUrl}/api/faq/delete/${id}`, {
    // await instance.post("/api/faq/create",{
    method: "delete",
    data: {},
  });
  return res;
};
// reviews get reviews api
const allReviews = async () => {
  const res = await instance.get("/api/review/list");
  return res;
};
// post add reviews api
const postAddReviews = async (reviewData: any) => {
  const res = await instance.post("/api/review/create", {
    status: 1,
    ...reviewData,
  });
  return res;
};
// delete reviews
const deleteReviews = async (id: any) => {
  const res = instance.delete(`/api/review/delete/${id}`);
  return res;
};
// post add admin api
const adminPost = async (adminData: any) => {
  const res = await instance
    .post(`${BaseUrl}/api/admin/inviteAdmin`, {
      email: adminData?.email,
    })
    .then((response: any) => {
      if (response.status === 200) {
        alert("Email added successfully");
        return response;
      }
    })
    .catch((error: any) => {});

  return res;
};
// add bundles
const postAddBundles = async (bundlesData: any) => {
  const res = await axios(`${BaseUrl}/api/review/create`, {
    // await instance.post("/api/faq/create",{
    method: "post",
    data: {
      name: bundlesData?.name,
      imageUrl: bundlesData?.imageUrl,
      retailPrice: bundlesData?.retailPrice,
      sellingPrice: bundlesData?.sellingPrice,
      description: bundlesData?.description,
      status: bundlesData?.status,
    },
  })
    .then((response: any) => {
      if (response.status === 200) {
        alert("Reviews added successfully");
        return response;
      }
    })
    .catch((error: any) => {});

  return res;
};
//get user
const getUser = async () => {
  const res = await instance.get("/api/user/list");
  return res;
};
//add article
const postAddArticle = async (articData: any) => {
  const res = await axios(`${BaseUrl}/api/articles/create`, {
    method: "post",
    data: {
      topicId: 1,
      name: articData?.name,
      imageUrl: articData?.imageUrl,
      profileUrl: articData?.profileUrl,
      title: articData?.title,
      description: articData?.description,
    },
  });

  return res;
};
const deletearticle = async (id: any) => {
  try {
    const res = await instance.delete(`/api/articles/delete/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};
const editArticle = async (id: any, payload: any) => {
  try {
    const res = await instance.put(`/api/articles/update/${id}`, payload);
    return res;
  } catch (err) {
    return err;
  }
};
const editFaq = async (id: any, payload: any) => {
  try {
    const res = await instance.put(`/api/faq/update/${id}`, payload);
    return res;
  } catch (err) {
    return err;
  }
};
const editReviwes = async (id: any, payload: any) => {
  try {
    const res = await instance.put(`/api/review/update/${id}`, payload);
    return res;
  } catch (err) {
    return err;
  }
};
const getTopicList = async () => {
  const res = await instance.get("/api/topic/list");
  return res;
};

const adminLogIn = async (payload: any) => {
  try {
    const res = await instance.post("/api/admin/logIn", payload);
    return res;
  } catch (error) {
    return error;
  }
};
const editUser = async (payload: any) => {
  try {
    const res = await instance.put("/api/user/edit", payload);
    return res;
  } catch (error) {
    return error;
  }
};

const createUserApi = async (payload: any) => {
  
  const res = await instance.post("/api/cart/createUser", payload);
  return res?.data;
};

const deleteUserApi = async (id: any) => {
  const payload = {
    id: id
  };

  try {
    const response = await instance.delete(`/api/user/delete`, {
      data: payload,
    });

    console.log("deleteUser response: ", response);

    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

const userTourList = async (userId?: any) => {
  const res = await instance.post(`/api/getUserToursList`, {
    id: userId
  });
  return res.data;
};

// get api for all bundles

const getBundles = async () => {
  const res = await instance.get("/api/bundles/list");
 
  return res;
};

// create bundle api
const createBundles = async (payload: any) => {
  const res = await instance.post("/api/bundles/create", payload);
  return res;
};
// update bundle api
const updateBundles = async (payload: any) => {
  const res = await instance.put("/api/bundles/update", payload);
  return res;
};

const getAdmins = async () => {
  const res = await instance.get("/api/admin/getAllAdminInfo");

  return res;
};
const inviteAdmin = async (payload: any ,isAuthenticate:any) => {
  
  const res = await instance.post("/api/admin/inviteAdmin", payload ,{headers:{
    auth:isAuthenticate
  }});

  return res;
};

const updateOffline = async (id: any) => {
  const res = await instance.get(`/api/tours/updateOffline/${id}`)
  return res;
};

const createAdminPermissions = async (payload: any, id: any) => {
  try {
    const res = await instance.post("/api/admin/subAdminPermissions", {
      ...payload,
      id: id,
    });
    return res;
  } catch (err) {
    return err;
  }
};
const updateAdminPermissions = async (payload: any, id?: any) => {
  try {
    const res = await instance.put(`/api/admin/editPermissions/${id}`, payload);
    return res;
  } catch (err) {
    return err;
  }
};
///api/admin/subAdminPermissions
const createTopics = async (payload: any) => {
  const res = await instance.post("/api/topic/create", payload);

  return res;
};

const articlesList = async () => {
  const res = await instance.get("/api/articles/list");

  return res;
};

const singletourList = async (endpiont?: any) => {
  const res = await instance.post(`/api/tours/list`,{tourId:endpiont});
  return res.data;
};
const createStops = async (payload: any) => {
  const res = await instance.post(`/api/tourPoints/create`, payload);
  return res.data;
};
const createTours = async (payload: any) => {
  const res = await instance.post(`/api/tours/create`, payload);
  return res;
};

const uploadAudio = async (payload: any) => {
  const formData = new FormData();
  formData.append("audio", payload);
  try {
    const res = await instance.post("api/audiosUpload", formData);

    return res;
  } catch (error) {
  }
};
const removeAudio = async (audioUrl: string) => {
  try {
    const res = await instance.delete(`/api/tourPoints/deleteAudio?fileName=${encodeURIComponent(audioUrl)}`);

    return res;
  } catch (error) {
  }
};

const uploadImage = async (payload: any) => {
  const formData = new FormData();
  formData.append("image", payload);
  const res = await instance.post("api/imagesUpload", formData);
  return res;
};
const removeImage = async (imageUrl: string) => {
  try {
    const res = await instance.delete(`/api/tourPoints/deleteImage?fileName=${encodeURIComponent(imageUrl)}`);

    return res;
  } catch (error) {
  }
};

const deletePlace = async (id: any) => {
  try {
    const res = await instance.delete(`/api/places/delete/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};

const deleteTour = async (id: any) => {
  try {
    const res = await instance.delete(`/api/tours/delete/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};

const deleteBundle = async (id: any) => {
  try {
    const res = await instance.delete(`/api/bundles/delete/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};

const editPlace = async (id: any, payload: any) => {
  try {
    const res = await instance.put(`/api/places/update/${id}`, payload);
    return res;
  } catch (err) {
    return err;
  }
};
const editTous = async (id: any, payload: any) => {
  try {
    const res = await instance.put(`/api/tours/update/${id}`, payload);
    return res;
  } catch (err) {
    return err;
  }
};
const editStop = async (id: any, payload: any) => {
  try {
    const res = await instance.put(`/api/tourPoints/update/${id}`, payload);
    
    return res;
  } catch (err) {
    return err;
  }
};
const editStopList = async (payload: any) => {
  try {
    const res = await instance.put(`/api/tourPoints/updateList/`, payload);
    return res;
  } catch (err) {
    return err;
  }
};

const deleteStop = async (id: any) => {
  try {
    const res = await instance.delete(`/api/tourPoints/delete/${id}`);
    
    return res;
  } catch (err) {
    return err;
  }
};

const changePassword = async (payload: any) => {
  try {
    const res = await instance.post("/api/admin/changePassword", payload);
    
    return res;
  } catch (err) {
    return err;
  }
};
const addSaftyInfo = async (payload: any) => {
  try {
    const res = await instance.post("/api/safetyInfo/create", payload);
   
    return res;
  } catch (err) {
    return err;
  }
};
const getSaftyInfo = async (id: any) => {
  try {
    const res = await instance.get(`api/safetyInfo/list/${id}`);
  
    return res;
  } catch (err) {
    return err;
  }
};
const updateSaftyInfo = async (id: any, payload: any) => {
  try {
    const res = await instance.put(`api/safetyInfo/update/${id}`, payload);
    
    return res;
  } catch (err) {
    return err;
  }
};

///safetyInfo/delete/

const deleteSaftyInfo = async (id: any) => {
  try {
    const res = await instance.delete(`api/safetyInfo/delete/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};

const dashboardAnalytics = async (payload: any) => {
  try {
    const res = await instance.post(`api/admin/analytics`, payload);
   
    return res;
  } catch (err) {
    return err;
  }
};
//  delete admin user
const deleteAdminUser = async (id: any) => {
  try {
    const res = await instance.delete(`api/admin/delete/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};
// forgot password post api
const forgotPassword = async (payload: any) => {
  try {
    const res = await instance.post("/api/admin/forgotPassword", payload);
   
    return res;
  } catch (err) {
    return err;
  }
};
// forgot password post api
const resetPassword = async (payload: any, token: any) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `${token}`,
  };
  // Make GET request
 
  try {
    const res = await axios.post(
      `${BaseUrl}/api/admin/resetPassword`,
      payload,
      { headers }
    );
    return res;
  } catch (err) {
    return err;
  }
};
// get otp post api
const getOtp = async (payload: any) => {
  try {
    const res = await instance.post("/api/admin/verifyOtp", payload);
    return res;
  } catch (err) {
    return err;
  }
};
// tours get api
const getTourSave = async (id: any) => {
  const res = await instance.post(`api/singleTourDirection`, id);
  return res;
};
// const getSingleTourDirection = async (id: any) => {
//   const res = await instance.get(`api//singleTourDirection/${id}`);
//   return res;
// };
const getSingleTourDirection = async (payload: any) => {
  console.log("payload",payload);
  
  try {
    const res = await instance.post("api/tours/list", payload);
    return res;
  } catch (err) {
    return err;
  }
};

//get bookings list
const getBookings = async () => {
  const res = await instance.get("/api/bookings/list");
  return res;
};

//videos apis
const getVideoById = async (id:any) => {
  const res = await instance.get("/api/videos/" + id);
  return res;
};
const createVideo = async (payload:any) => {
  const res = await instance.post("/api/videos/", payload);
  return res;
};
const updateVideo = async (payload:any) => {
  const res = await instance.put("/api/videos/", payload);
  return res;
};

const services = {
  getVideoById,
  createVideo,
  updateVideo,
  getAllPlace,
  getAllTour,
  getAllFaq,
  postAddPlace,
  postAddFaq,
  removeAddFaq,
  postAddReviews,
  allReviews,
  deleteReviews,
  adminPost,
  postAddBundles,
  getUser,
  postAddArticle,
  // getAllArticle,
  getTopicList,
  adminLogIn,
  editUser,
  getBundles,
  createBundles,
  updateBundles,
  getAdmins,
  inviteAdmin,
  createTopics,
  articlesList,
  deletearticle,
  singletourList,
  createStops,
  createTours,
  uploadAudio,
  uploadImage,
  deletePlace,
  deleteTour,
  deleteBundle,
  editPlace,
  editTous,
  editStop,
  deleteStop,
  changePassword,
  updateOffline,
  addSaftyInfo,
  getSaftyInfo,
  updateSaftyInfo,
  deleteSaftyInfo,
  dashboardAnalytics,
  createAdminPermissions,
  updateAdminPermissions,
  deleteAdminUser,
  forgotPassword,
  resetPassword,
  getOtp,
  getTourSave,
  editArticle,
  editFaq,
  editReviwes,
  getSingleTourDirection,
  createUserApi,
  deleteUserApi,
  userTourList,
  editStopList,
  getBookings,
  removeAudio,
  removeImage
};

export default services;
