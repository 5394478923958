import React, { useEffect, useState } from "react";
import PlacecardComponent from "../../component/places/PlacecardComponent";
import SearchComponent from "../../component/search/SearchComponent";
import ModalComponent from "../../component/places/modal/ModalComponent";
import services from "../../services/services";
import AddPlacesModal from "../../component/places/modal/AddPlacesModal";
import { useAppDispatch } from "../../store/app/store";
import { useSelector } from "react-redux";
import { PlacesList } from "../../store/features/placesListSlice";

const Places = () => {
  const { adminPermission } = useSelector((state: any) => state.counter);
  const dispatch: any = useAppDispatch();
  const places = useSelector((state: any) => state.placesList);
  const [allplaces, setAllPlaces] = useState<any>(false);
  const [filterData, setFilterData] = useState<any>();
  const [searchValue, setSearchValue] = useState();

  const getdata = async () => {
    try {
      const res = await services.getAllPlace();
      

      setAllPlaces(res?.data?.data);
      setFilterData(res?.data?.data);
    } catch (err) {
   
    }
  };


  const getSearchData = (data: any, value: any) => {
    setSearchValue(value);
    setFilterData(data);
  };

  useEffect(() => {
    // if (!places?.data?.data?.data?.length) {
    // dispatch(PlacesList());
    // }
  }, []);

  if (adminPermission?.is_admin == 2 && !adminPermission?.places) {
    return (
      <h1 className="text-3xl font-openSans font-bold text-aqua items-center flex justify-center h-[80vh]">
        You don't have permission
      </h1>
    );
  }

  return (
    <>
      <div>
        <div className="flex justify-between px-12 pt-7">
          <SearchComponent
            getSearchData={getSearchData}
            data={places?.data?.data?.data}
          />
          <AddPlacesModal />
        </div>
        <div className=" grid  gap-6 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 mt-9 mx-14 mb-6">
          {filterData?.length
            ? filterData?.map((elm: any) => <PlacecardComponent place={elm} />)
            : places?.data?.data?.data.length &&
              !searchValue &&
              places?.data?.data?.data?.map((elm: any) => (
                <PlacecardComponent place={elm} />
              ))}
        </div>
      </div>

      {filterData?.length || !searchValue || (
        <div className="w-full h-[60vh] flex justify-center items-center">
          <h2 className="text-center text-aqua font-bold font-openSans w-full text-4xl">
            {" "}
            {searchValue} is not available
          </h2>
        </div>
      )}
    </>
  );
};

export default Places;
