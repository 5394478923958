import React, { useEffect, useRef, useState } from "react";
import Images from "../../../constant/Images";
import SelectComponent from "../../selectComponent/SelectComponent";
import { EditFaqinput } from "../../../constant/string";
import InputComponent from "../../inputComponent/InputComponent";
import services from "../../../services/services";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
const FaqEdit = ({ data, getdata }: any) => {
  const [faqData, setFaqData] = useState<any>({
    name: data?.name,
    tourId: data?.tourId,
    description: data?.description,
    topQuestion: data?.topQuestion,
  });
  const { Tours } = useSelector((state: any) => state.tours);
  const [showModal, setShowModal] = React.useState(false);
  const handleAddPlaces = async (payload: any) => {
    const res: any = await services.editFaq(data?.id, payload);
    if (res?.status == 200) {
      toast.success(res?.data.message);
      getdata();
    } else {
      toast.dismiss();
      toast.error("Something Went Wrong");
    }
  };
  useEffect(() => {
    if (showModal) {
      setFaqData({
        name: data?.name,
        tourId: data?.tourId,
        description: data?.description,
        topQuestion: data?.topQuestion,
      });
    }
  }, [showModal]);
  const formik = useFormik({
    initialValues: faqData,
    validationSchema: Yup.object({
      name: Yup.string().trim().required("This field is required"),
      tourId: Yup.string().trim().required("This field is required"),
      description: Yup.string().trim().required("This field is required"),
      topQuestion: Yup.string().trim().required("This field is required"),
    }),
    onSubmit: (values) => {
      handleAddPlaces({
        ...values,
      });
      setShowModal(false);
    },
    enableReinitialize: true,
  });
  return (
    <>
      <img
        className="font-roboto font-medium text-xl  w-6 h-6 cursor-pointer"
        src={Images.edit}
        alt=""
        onClick={() => {
          setShowModal(true);
          formik.handleReset(formik.values);
        }}
      />
      {showModal ? (
        <>
          <div className="justify-center  box-border  items-center flex  fixed inset-0 z-50 outline-none">
            <div className="relative w-[950px] h-[600px] bg-white my-6 rounded overflow-y-auto ">
              {/*content*/}
              {/*header*/}
              <div className="flex justify-between text-xl font-semibold bg-lightaqua w-[100%] h-[70px] items-center rounded">
                <div></div>
                <h3 className="font-sans font-bold">Add Places</h3>
                <div className="bg-aquadark w-[70px] h-[70px] rounded-xl flex justify-center ">
                  <button
                    className="p-1"
                    onClick={() => {
                      formik.handleReset(formik.values);
                      setShowModal(false);
                    }}
                  >
                    <img
                      className=" text-black h-7 w-7 outline-none focus:outline-none "
                      src={Images.closecircle}
                      alt=""
                    ></img>
                  </button>
                </div>
              </div>
              {/*body*/}
              <form className="px-12 py-9 " onSubmit={formik.handleSubmit}>
                <div className="">
                  {EditFaqinput.map((elm: any) =>
                    elm?.key == "option" ? (
                      <SelectComponent
                        id={elm?.name}
                        landing={true}
                        handlechange={formik.handleChange}
                        value={(formik as any)?.values?.[elm?.name]}
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        key={elm?.key1}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                        option={
                          elm?.key1 == "tourId"
                            ? (Tours as any)?.data?.data
                            : elm?.option
                        }
                      />
                    ) : (
                      <InputComponent
                        key={elm?.name}
                        id={elm?.name}
                        handlechange={formik.handleChange}
                        accept={elm?.accept}
                        value={
                          elm?.type == "file"
                            ? ""
                            : (formik as any)?.values?.[elm?.name]
                        }
                        name={elm?.name}
                        label={elm?.label}
                        placeholder={elm?.placeHolder}
                        type={elm?.type}
                        error={(formik as any).errors[elm.name]}
                        touched={(formik as any).touched[elm.name]}
                      />
                    )
                  )}
                </div>
                <div className="mt-9 flex">
                  <button
                    className="w-[150px] h-[50px] border rounded border-aqua  text-aqua"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      formik.handleReset(formik.values);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-[150px] h-[50px] border rounded bg-aqua text-white ml-6"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
              {/*footer*/}
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};
export default FaqEdit;
