import React from "react";
import Images from "../../constant/Images";
import { ImgUrl } from "../../services/InstanceAxios";

const TableComponent = ({ columns, allTour }: any) => {
  return (
    <div className="w-full">
      <table className="w-full ">
        <thead className=" text-lightgray text-xs font-Roboto font-normal">
          <tr className="text-left">
            {columns.map((columns: any) => (
              <th key={columns.id} className="pb-2">
                {columns.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="">
          {allTour?.map((elm: any) => (
            <tr className="bg-white border-b border-aqua p-2 ">
              <td className="pb-2 pt-2">
                <tr>
                  <img
                    src={ImgUrl + elm?.image}
                    alt=""
                    className="h-11 w-11 rounded-md"
                  />
                  <td className="ml-5">
                    <p className="font-openSans text-dimblack text-xl font-semibold ml-4">
                      {allTour?.label}
                    </p>
                    <p className="font-openSans text-lightgray font-medium text-base ml-4">
                      {elm?.name}
                    </p>
                  </td>
                </tr>
              </td>
              <td className="font-openSans text-dimblack font-medium text-xl">
                <tr>{elm?.count}</tr>
              </td>
              {/* <td className="">
              <tr className="flex">
                <img className="mr-1" src={Images.intarrow} alt="" />
                <p className="font-openSans text-dimblack font-medium text-xl">
                  5%
                </p>
              </tr>
            </td> */}
              <td className="">
                <p className="font-openSans text-dimblack font-medium text-xl">
                  {elm?.total ? elm?.total : 0}
                </p>
              </td>
              <td>
                {elm?.type == 2 ? (
                  <img src={Images.car} alt="driving" className=" " />
                ) : (
                  <img src={Images.walking} alt="walking" />
                )}
                {/* <img src={Images.car} alt="" />
              <img src={Images.walking} alt="" /> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
