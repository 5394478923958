
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import counterSlice from '../features/counterSlice'
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import getUsersSlice from '../features/getUsersSlice';
import { useDispatch } from 'react-redux';
import getBundlesSlice from '../features/getBundlesSlice';
import getAdminsSlice from '../features/getAdminsSlice';
import placesListSlice from '../features/placesListSlice';
import articlesListSlice from '../features/articlesListSlice';
import topicListSlice from '../features/topicListSlice';
import toursSlice from '../features/toursSlice';
import singleTourSlice from '../features/singleTourSlice';

// ...

const persistConfig:any = {
  key: "root",
  storage: storage,
  // blacklist: ["tourListSlice"],
};

const rootReducers = combineReducers({
  counter:counterSlice,
  getUsers:getUsersSlice,
  getBundles:getBundlesSlice,
  getAdmins:getAdminsSlice,
  placesList:placesListSlice,
  articlesList:articlesListSlice,
  topicList:topicListSlice,
  tours:toursSlice,
  singleTourSlice:singleTourSlice
})
const persistedReducer = persistReducer(persistConfig, rootReducers)
export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
})


export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
