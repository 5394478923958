export const languages = [
  {
    code: "en",
    name: "English",
    flagUrl: "https://www.worldometers.info//img/flags/small/tn_uk-flag.gif",
  },
  {
    code: "cs",
    name: "Czech",
    flagUrl: "https://www.worldometers.info//img/flags/small/tn_ez-flag.gif",
  },
  {
    code: "nl",
    name: "Dutch",
    flagUrl: "https://www.worldometers.info//img/flags/small/tn_nl-flag.gif",
  },
  {
    code: "de",
    name: "German",
    flagUrl: "https://www.worldometers.info//img/flags/small/tn_gm-flag.gif",
  },
  {
    code: "es",
    name: "Spanish",
    flagUrl: "https://www.worldometers.info//img/flags/small/tn_sp-flag.gif",
  },
  {
    code: "fr",
    name: "French",
    flagUrl: "https://www.worldometers.info//img/flags/small/tn_fr-flag.gif",
  },
  {
    code: "ta",
    name: "Tamil",
    flagUrl: "https://www.worldometers.info//img/flags/small/tn_in-flag.gif",
  },
  {
    code: "hi",
    name: "Hindi",
    flagUrl: "https://www.worldometers.info//img/flags/small/tn_in-flag.gif",
  },
  {
    code: "it",
    name: "Italian",
    flagUrl: "https://www.worldometers.info//img/flags/small/tn_it-flag.gif",
  },
  {
    code: "pl",
    name: "Polish",
    flagUrl: "https://www.worldometers.info//img/flags/small/tn_pl-flag.gif",
  },
  {
    code: "ru",
    name: "Russian",
    flagUrl: "https://www.worldometers.info//img/flags/small/tn_rs-flag.gif",
  },
  {
    code: "sv",
    name: "Swedish",
    flagUrl: "https://www.worldometers.info//img/flags/small/tn_sw-flag.gif",
  },
  {
    code: "zh",
    name: "Chinese",
    flagUrl: "https://www.worldometers.info//img/flags/small/tn_ch-flag.gif",
  },
  {
    code: "da",
    name: "Danish",
    flagUrl: "https://www.worldometers.info//img/flags/small/tn_da-flag.gif",
  },
  {
    code: "zh",
    name: "Greek",
    flagUrl: "https://www.worldometers.info//img/flags/small/tn_gr-flag.gif",
  },
];
