import React, { useEffect, useState } from "react";
import Images from "../../constant/Images";
import InputComponent from "../../component/inputComponent/InputComponent";
import { loginUser } from "../../constant/string";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../store/app/hooks";
import { StoreCookie } from "../../Auth/sessionStore";
import { Link, useNavigate } from "react-router-dom";
import services from "../../services/services";
import { useStore } from "react-redux";
import setUpInterceptor from "../../services/Intercepter";
import { setadminPermission, updateAdmin } from "../../store/features/counterSlice";
import { getAdminsThank} from '../../store/features/getAdminsSlice'
import { useSelector } from "react-redux";
const Login = ({ auth }: any) => {
  const [isLogIn, setIsLogIn] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch:any = useAppDispatch();

  // window.location.reload();
  const handleLogin = async (payload: any) => {
    const res: any = await services.adminLogIn(payload);
    if ((res as any)?.status == 200) {
 
      toast.success("Login  successfully");
      // StoreCookie.setItem("userToken", (res as any)?.data?.sessionData?.uid);
      // localStorage.setItem("token" ,JSON.stringify((res as any)?.data?.sessionData?.uid) )
      setUpInterceptor(res?.data.sessionData?.uid);
      dispatch(updateAdmin((res as any)?.data?.sessionData));
      setIsLogIn(false);
      
      dispatch(setadminPermission({...res?.data?.adminInfo?.adminDetails,is_admin:res?.data?.adminInfo?.is_admin}))
      navigate("/dashboard");
    } else {
 
      res
        ? toast.error(res?.response?.data?.message)
        : res?.response?.data?.message
        ? toast.error(res?.response?.data?.message)
        : toast.error("something went wrong");
      setTimeout(() => {
        setIsLogIn(false);
      }, 5000);
    }
  };
  const validate = (values: any) => {
    const errors: any = {};

    if (!values.password) {
      errors.password = "This Field Is Required";
    }

    if (!values.email) {
      errors.email = "This Field Is Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validate,
    onSubmit: (values) => {
      setIsLogIn(true);
      handleLogin(values);
      // alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="flex flex-col justify-center items-center w-1/3 m-auto ">
        <img src={Images.logo} alt="" />
        <h3 className="font-bold font-ProximaNovaAltBold text-7xl xl:text-6xl mt-11">
          Login
        </h3>
        <form className="w-full mt-11" onSubmit={formik.handleSubmit}>
          {loginUser.map((elm: any, id: number) => (
            <InputComponent
              type={elm.type}
              name={elm.name}
              key={elm.name + id}
              handleBlur={formik.handleBlur}
              touched={(formik as any).touched[elm.name]}
              label={elm.label}
              placeholder={elm.placeholder}
              handlechange={formik.handleChange}
              value={(formik as any)?.values?.[elm?.name]}
              error={(formik as any).errors?.[elm.name]}
            />
          ))}
          <p className="text-xl xl:text-lg font-ProximaNovaAltRgRegular font-normal text-[#616161] mt-3.5">
            <Link to="/forgotpassword">Forgot Password?</Link>
          </p>
          <button
            className="bg-aqualight w-full mt-12 py-4 rounded-md text-white font-bold font-ProximaNovaAltBold"
            type="submit"
            disabled={isLogIn}
          >
            {isLogIn ? "Processing...  " : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
